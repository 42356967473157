<template>
  <div>
    <div class="setting-colr rounded mb-1" @click="show = !show">
      <p class="mb-0">สไลด์โชว์</p>

      <i class="fas fa-plus-circle" />
    </div>

    <div v-if="show" class="clrr-setting">
      <div v-if="slides && slides.length" class="row">
        <div
          v-for="(url, index) in slides"
          :key="url"
          class="col-6 my-1"
        >
          <img :src="url" alt="Preview" class="preview-image" />
          <button class="btn btn-danger mt-1 rounded" @click="removeImage(index)">ลบ</button>
        </div>
      </div>

      <div class="input-file-container">
        <i class="fal fa-plus cursor-pointer" />
        <label for="img-file" class="input-file-label">เพิ่มรูปภาพ</label>
        <input
          id="img-file"
          multiple
          type="file"
          class="input-file"
          accept="image/*"
          @change="handleFileChange"
        />
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  props: ['slides'],
  data: () => ({
    show: false,
    imagePreviewUrls: [],
    agent: null,
  }),
  mounted() {},
  methods: {
    async handleFileChange(event) {
      let files = [];
      event.target.files.forEach(async file => {
        const base64 = this.getBase64Image(file);
        // this.imagePreviewUrls.push(base64);
        files.push(base64);
      })

      // console.log(files)
      this.$emit("setData", files);
    },
    getBase64Image(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    removeImage(index) {
      this.$emit('removeData', index)
    }
  },
};
</script>
